<template>
    <div class="app-container">
        <div class="searchForm_box">
            <el-form :inline="true" :model="searchForm" size="small">
                <el-form-item>
                    <el-input placeholder="請輸入內容" v-model="searchForm.searchTxt" class="input-with-select">
                        <el-select v-model="searchForm.searchChoose" slot="prepend" placeholder="請選擇" style="width: 130px">
                            <el-option label="店鋪ID" value="shop_id"></el-option>
                            <el-option label="店鋪名稱" value="shop_name"></el-option>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                </el-form-item>

            </el-form>

        </div>

        <!-- 表格 -->
        <el-table v-loading="tableLoading" :data="shopList" :border="true" class="table">
            <el-table-column label="店鋪ID" width="80" prop="shop_id" align="center"></el-table-column>
            <el-table-column label="代理商" prop="agent.agent_name" align="center"></el-table-column>
            <el-table-column label="店鋪名稱" prop="shop_name" align="center"></el-table-column>
            <el-table-column label="收銀機賬號" prop="shop_phone" align="center"></el-table-column>
            <el-table-column label="店鋪類型" prop="shop_type" width="100" align="center">
                <template slot-scope="scope">
            <span>{{
              scope.row.shop_type == "1"
                ? "RFID"
                : scope.row.shop_type == "2"
                ? "磁標"
                : scope.row.shop_type == "3"
                ? "雲值守"
                : ""
            }}</span>
                </template>
            </el-table-column>
            <el-table-column label="支付方式" prop="pay_way" align="center">
                <template slot-scope="scope">
            <span>{{
              scope.row.pay_way == "1"
                ? "支付寶微信支付"
                : scope.row.pay_way == "2"
                ? "聚合碼支付"
                : scope.row.pay_way == "3"
                ? "銀聯商務支付"
                : scope.row.pay_way == "4"
                ? "平安銀行"
                : ""
            }}</span>
                </template>
            </el-table-column>
            <el-table-column label="雲值守" prop="cloud_watch_status" align="center">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.cloud_watch_status"
                            active-color="#13ce66"
                            inactive-color="#cccccc"
                            :active-value="1"
                            :inactive-value="0"
                            @change="setWatch(scope.row,'cloud_watch_status')">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="禁止顧客進店" prop="disable_buyer_enter" align="center">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.disable_buyer_enter"
                            active-color="#13ce66"
                            inactive-color="#cccccc"
                            :active-value="1"
                            :inactive-value="0"
                            @change="setWatch(scope.row,'disable_buyer_enter')">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="掃碼購" prop="is_scan" align="center">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.is_scan"
                            active-color="#13ce66"
                            inactive-color="#cccccc"
                            :active-value="1"
                            :inactive-value="0"
                            @change="setWatch(scope.row,'is_scan')">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="快速開門" prop="is_fast" align="center">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.is_fast"
                            active-color="#13ce66"
                            inactive-color="#cccccc"
                            :active-value="1"
                            :inactive-value="0"
                            @change="setWatch(scope.row,'is_fast')">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="一鍵鎖門" prop="shop_lock" align="center">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.shop_lock"
                            active-color="#13ce66"
                            inactive-color="#cccccc"
                            :active-value="1"
                            :inactive-value="0"
                            @change="setWatch(scope.row,'shop_lock')">
                    </el-switch>
                </template>
            </el-table-column>


            <el-table-column label="負庫存售賣" prop="stock_sale_status" align="center">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.stock_sale_status"
                            active-color="#13ce66"
                            inactive-color="#cccccc"
                            :active-value="1"
                            :inactive-value="0"
                            @change="setWatch(scope.row,'stock_sale_status')">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleDoor(scope.row,1)">開進門</el-button>
                    <el-button type="danger" size="mini" @click="handleDoor(scope.row,3)">開出門</el-button>
                    <el-button size="small" type="text" icon="el-icon-edit" @click="setPwd(scope.row)">初始收銀機密碼</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分頁 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import {listShop, editShop, openDoor} from "@/api/shop";

    export default {
        name: "shopset",
        data() {
            return {
                searchForm: {
                    searchTxt: "",
                    searchChoose: 'shop_name',
                    shop_id: '',
                    shop_name: ''
                },
                tableLoading: false,
                page: 1,
                pageSize: 50,
                total: 0,
                shopList: [],
            };
        },
        mounted() {
            this.getShopList();
        },
        methods: {
            //設置開關
            setWatch(row, tag) {
                let val = ''
                let params = {
                    "shop_id": row.shop_id
                }
                let btn_txt = ''

                if (tag == "disable_buyer_enter" && row.shop_lock==1) {
                    this.$modal.alertWarning("鎖店中，無法操作,如需操作請解除鎖店");
                    if (row.disable_buyer_enter) {
                        row.disable_buyer_enter = 0
                    } else {
                        row.disable_buyer_enter = 1
                    }
                    return false
                }

                switch (tag) {
                    case "stock_sale_status":
                        val = row.stock_sale_status
                        params['stock_sale_status'] = val
                        btn_txt = "負庫存售賣"
                        break
                    case "is_fast":
                        val = row.is_fast
                        params['is_fast'] = val
                        btn_txt = "快速開門"

                        break
                    case "shop_lock":
                        val = row.shop_lock
                        params['shop_lock'] = val
                        params['disable_buyer_enter'] = val
                        row.disable_buyer_enter = val
                        btn_txt = "一鍵鎖門"

                        break
                    case "cloud_watch_status":
                        val = row.cloud_watch_status
                        params['cloud_watch_status'] = val
                        btn_txt = "雲值守"

                        break
                    case "is_scan":
                        val = row.is_scan
                        params['is_scan'] = val
                        btn_txt = "掃碼購"
                        break
                    case "disable_buyer_enter":
                        val = row.disable_buyer_enter
                        params['disable_buyer_enter'] = val
                        btn_txt = "禁止顧客進店"
                        break

                }
                let txt = ''
                let old_val = ''
                if (val == 1) {
                    txt = '開啟'
                    old_val = 0
                } else {
                    txt = '關閉'
                    old_val = 1
                }

                this.$modal.confirm(row.shop_name + ",確定要<span style='color:red'>" + txt + btn_txt + "</span>？")
                    .then(function () {
                        return editShop(params)
                    }).then(() => {
                    this.$modal.msgSuccess("操作成功");
                }).catch(() => {
                    switch (tag) {
                        case "stock_sale_status":
                            row.stock_sale_status = old_val
                            break
                        case "is_fast":
                            row.is_fast = old_val
                            break
                        case "shop_lock":
                            row.shop_lock = old_val
                            row.disable_buyer_enter = old_val
                            break
                        case "cloud_watch_status":
                            row.cloud_watch_status = old_val
                            break
                        case "is_scan":
                            row.is_scan = old_val
                            break
                        case "disable_buyer_enter":
                            row.disable_buyer_enter = old_val
                            break
                    }
                })
            },
            handleDoor(row, door) {
                let params = {"shop_id": row.shop_id, 'wg_sn': row.wg_sn, door}
                openDoor(params).then(response => {
                    this.$modal.msgSuccess("開門成功");
                }).catch(() => {
                });
            },
            setPwd(row) {
                this.$modal.confirm("確定要重置<span style='color:red'>" + row.shop_name + "</span>收銀機登錄密碼？")
                    .then(function () {
                        return editShop({"shop_id": row.shop_id, "password": "123"})
                    }).then(() => {
                    this.$modal.msgSuccess("密碼重置成功");
                }).catch(() => {
                })
            },
            //搜索
            handleQuery() {
                this.page = 1
                this.getShopList();
            },
            // 獲取店鋪列表
            getShopList() {
                let params = {
                    page: this.page,
                    pageSize: this.pageSize,
                    shop_id:
                        this.searchForm.searchChoose == "shop_id" ? this.searchForm.searchTxt : "",
                    shop_name:
                        this.searchForm.searchChoose == "shop_name" ? this.searchForm.searchTxt : "",
                    status: 2
                }
                this.tableLoading = true;
                listShop(params).then(response => {
                    this.shopList = response.data.list;
                    this.total = response.data.count;
                    this.tableLoading = false;
                }).catch(() => {
                    this.tableLoading = false
                });
            },
            // 分頁
            handleSizeChange(val) {
                this.page = val;
                this.getShopList();
            },
            handleCurrentChange(val) {
                this.page = val;
                this.getShopList();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .thestoremenu {
        .users_form {
            padding: 5px 0px;
            display: flex;
            align-items: center;

            .el-form {
                width: 300px;
                height: 40px;
            }

            .el-button {
                margin: 0px 10px;
            }
        }

        .avatar-uploader {
            width: 150px;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader:hover {
            border-color: #409eff;
        }

        .avatar-uploader-icon {
            color: #8c939d;
            width: 150px;
            height: 150px;
            text-align: center;
            padding: 40px 0 0 0;
            box-sizing: border-box;

            > i {
                font-size: 28px;
            }
        }

        .avatar {
            width: 150px;
            height: 150px;
            display: block;
        }
    }
</style>